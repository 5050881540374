"use client"
import Icon from "./icon.png"
import Image from "next/image"
import { useState } from "react"
import { useCart } from "@local/cart/src/useCart"
import {
  getFirstVariantInStock,
  useMultiCartStore,
} from "@local/product-option-manager"
import { useGiftCart } from "@local/cart/src/useGiftCart"
import type { CartItem } from "@local/cart/src/types"
import Cookies from "js-cookie"
import Link from "next/link"
import { isBrowser, useLocalStorage } from "@local/utils"
import { useRouter } from "next/navigation"
import fetchPayload from "@local/payload-client/src/fetchPayload"
import { Payload } from "@local/payload-client/src/types"
import { useGiftWrap } from "@local/gift-wrap/src/hook"

const CartNuker = () => {
  const { reset } = useCart()
  const { clearGiftCart } = useGiftCart()
  const { clear } = useGiftWrap()

  const nuke = async () => {
    reset(false)
    clearGiftCart()
    clear()
  }

  const anihilation = async () => {
    window.localStorage.removeItem("cart")
    window.localStorage.removeItem("giftCart")
  }

  return (
    <p className="space-y-2">
      <button className="p-2 text-white bg-red-600" onClick={nuke}>
        Nuke Cart
      </button>
      <button
        className="p-2 ml-2 text-white bg-red-600"
        onClick={() => reset(false, "baerskintactical")}
      >
        Nuke Cart as bst
      </button>
      {isBrowser() && (
        <button
          className="p-2 ml-2 text-white bg-red-600"
          onClick={anihilation}
        >
          REALLY nuke Cart
        </button>
      )}
    </p>
  )
}

// const getProduct  = async (id: string) => {
//   const res = await fetch(`https://api.div.haus/products/${id}`)
//   const data = await res.json()
//   return data
// }

const getProduct = async (id: string) => {
  const res = await fetchPayload<Payload.Product>([
    `/products/${id}/`,
    "en",
    "?depth=1",
    {
      tags: ["crosssell", "products"],
    },
  ])

  return res
}

const LocaleNuker = () => {
  const nuke = async () => {
    Cookies.remove("country")
  }

  return (
    <p>
      <button className="p-2 text-white bg-red-600" onClick={nuke}>
        Nuke Locale/Region
      </button>
    </p>
  )
}

const CartFaker = () => {
  const { addItems } = useCart()
  const [forceAddValue, setForceAddValue] = useState("")

  const fakeIt = () => {
    const item = {
      productId: "647af324b1c30752d5e166e1",
      sku: "baerskin1-navy-m",
      slug: "baerskin-hoodie",
      bundleItems: null,
      variantId: "64887b4f47bd3d0001ae6d0c",
      variantName: "Men, Navy, M",
      productName: "The BÆRSkin Tactical Hoodie 2.0",
      quantity: 1,
      image: "https://assets.stg.div.haus/c149a5e109e8f8dfcfe0db9bbb06466d.png",
      prices: [
        {
          min: 3,
          max: null,
          value: 8995,
        },
        {
          min: 2,
          max: 3,
          value: 9495,
        },
        {
          min: 1,
          max: 2,
          value: 9995,
        },
      ],
    } as CartItem
    addItems({ items: [item] })
  }

  const fakeAgain = () => {
    const item = {
      productId: "647af324b1c30752d5e166e1",
      sku: "baerskin1-grey-xl",
      slug: "baerskin-hoodie",
      bundleItems: null,
      variantId: "64887b4f47bd3d0001ae6d07",
      variantName: "Men, Grey, XL",
      productName: "The BÆRSkin Tactical Hoodie 2.0",
      quantity: 1,
      image: "https://assets.stg.div.haus/6eb6df1f22f6e15f6924d740a8d462fa.png",
      prices: [
        {
          min: 1,
          max: 2,
          value: 9995,
        },
        {
          min: 2,
          max: 3,
          value: 9495,
        },
        {
          min: 3,
          max: null,
          value: 8995,
        },
      ],
    } as CartItem
    addItems({ items: [item] })
  }

  const fakeForceAdd = async () => {
    const product = await getProduct(forceAddValue)
    if (!product) return

    const cartItem = getFirstVariantInStock({ product, region: "en" })
    if (!cartItem) return

    const item = {
      productId: cartItem.productId,
      sku: cartItem.sku,
      slug: product.slug,
      bundleItems: null,
      variantId: cartItem.variantId,
      variantName: cartItem.variantName,
      productName: cartItem.productName,
      quantity: 1,
      image: product.images?.[0]?.image?.url,
      prices: [
        {
          min: 1,
          max: 2,
          value: product.prices?.[0]?.price,
        },
      ],
    } as CartItem
    addItems({ items: [item] })
  }

  return (
    <div className="flex flex-row gap-2">
      <button className="p-2 text-white bg-red-600" onClick={fakeIt}>
        Add Item
      </button>
      <button className="p-2 text-white bg-red-600" onClick={fakeAgain}>
        Alt
      </button>
      <div>
        <input
          type="text"
          className="text-black"
          value={forceAddValue}
          onChange={(e) => setForceAddValue(e.target.value)}
        />
        <button className="p-2 text-white bg-red-600" onClick={fakeForceAdd}>
          force add by ID (unstable)
        </button>
      </div>
    </div>
  )
}

const SkipToOrder = () => {
  const { storedValue, setValue } = useLocalStorage("cart", {})
  const { push } = useRouter()

  if (process.env.NEXT_PUBLIC_CHANNEL_ID !== "baerskintactical") {
    return null
  }

  const fullOrder = {
    state: {
      createdAt: "2023-08-29T06:56:15.658Z",
      error: null,
      id: "e9r4c3klo64axqez6z20nys4",
      items: [
        {
          productId: "647af324b1c30752d5e166e1",
          sku: "baerskin1-grey-l",
          slug: "baerskin-hoodie",
          bundleItems: null,
          variantId: "64e5df83091fdb000166c6f3",
          variantName: "Grey, L",
          productName: "The BÆRSkin Tactical Hoodie 2.0",
          quantity: 2,
          image:
            "https://assets.stg.div.haus/89f700ff9300f528dc14ffc262c836be.png",
          prices: [{ min: 1, max: 2, value: 9995 }],
          price: 9995,
        },
        {
          productId: "647aeb6cb1c30752d5e0cc53",
          sku: "vanguardsweater-black-l",
          slug: "baerskin-vanguard-sweater",
          bundleItems: null,
          variantId: "648886f847bd3d0001ae7ebc",
          variantName: "L, Black",
          productName: "BÆRSkin Vanguard Sweater",
          quantity: 1,
          image:
            "https://assets.stg.div.haus/58e243900fbc4e722a04cb09aacb8c24.png",
          prices: [{ min: 1, max: 2, value: 3498 }],
          price: 3498,
        },
        {
          productId: "647af643b1c30752d5e1b033",
          sku: "baerskinfem-grey-m",
          slug: "the-baerskin-tactical-hoodie-2-0-women",
          bundleItems: null,
          variantId: "64887f76bb5f220001834fba",
          variantName: "Grey, M",
          productName: "The BÆRSkin Tactical Hoodie 2.0 Women",
          quantity: 1,
          image:
            "https://assets.stg.div.haus/1fef0dbdd82b7448a0099ad85a84513e.jpg",
          prices: [{ min: 1, max: 2, value: 9995 }],
          price: 9995,
        },
        {
          productId: "647ae98ee74a03e6b22d4270",
          sku: "lumberjacket-green-l",
          slug: "baerskin-lumberjacket",
          bundleItems: null,
          variantId: "64886c19bb5f22000183277f",
          variantName: "L, Dark Green",
          productName: "BÆRSkin Lumberjacket",
          quantity: 1,
          image:
            "https://assets.stg.div.haus/70ddcdac5bb3ade75a25977cc3f280e2.png",
          prices: [{ min: 1, max: 2, value: 9960 }],
          price: 9960,
        },
      ],
      orders: [
        {
          bonusItems: [
            {
              image:
                "https://assets.div.haus/SWAT Thinsulate Beanie-web-image â 5.png",
              productId: "647f1cc8ccf118e34879594f",
              productName: "BÆRSkin Thinsulate Beanie",
              quantity: 1,
              sku: "swatbeanie-black",
              slug: "baerskin-thinsulate-beanie",
              price: 0,
              prices: [],
            },
            {
              image:
                "https://assets.div.haus/1bc8f7e687cb6fc34a7aa67f5a7faf7f.png",
              productId: "647af177b1c30752d5e12d80",
              productName: "Bionik Wallet",
              quantity: 1,
              sku: "menswallet-black",
              slug: "bionik-wallet",
              price: 0,
              prices: [],
            },
          ],
          createdAt: "2023-08-29T06:47:54.313Z",
          id: "84xqz9bqzo2mtdlrrftzx0yy",
          items: [
            {
              productId: "647af324b1c30752d5e166e1",
              sku: "baerskin1-grey-2xl",
              slug: "baerskin-hoodie",
              bundleItems: null,
              variantId: "64e5df83091fdb000166c6f5",
              variantName: "Grey, 2XL",
              productName: "The BÆRSkin Tactical Hoodie 2.0",
              quantity: 4,
              image:
                "https://assets.stg.div.haus/89f700ff9300f528dc14ffc262c836be.png",
              prices: [{ min: 1, max: 2, value: 9995 }],
              price: 9995,
            },
            {
              productId: "647af324b1c30752d5e166e1",
              sku: "baerskin3-grey-xl",
              slug: "baerskin-hoodie",
              bundleItems: null,
              variantId: "64e5df83091fdb000166c6f4",
              variantName: "Grey, XL",
              productName: "The BÆRSkin Tactical Hoodie 2.0",
              quantity: 1,
              image:
                "https://assets.stg.div.haus/89f700ff9300f528dc14ffc262c836be.png",
              prices: [{ min: 1, max: 2, value: 9995 }],
              price: 9995,
            },
          ],
          method: "PAYMENT_CARD",
          totals: {
            grandTotal: 49975,
            shipping: 0,
            subTotal: 49975,
            tax: 0,
            tip: 0,
          },
          uid: "87W2MCRR",
          updatedAt: "2023-08-29T06:55:30.558Z",
        },
        {
          bonusItems: [
            {
              image:
                "https://assets.div.haus/SWAT Thinsulate Beanie-web-image â 5.png",
              productId: "647f1cc8ccf118e34879594f",
              productName: "BÆRSkin Thinsulate Beanie",
              quantity: 1,
              sku: "swatbeanie-black",
              slug: "baerskin-thinsulate-beanie",
              price: 0,
              prices: [],
            },
            {
              image:
                "https://assets.div.haus/1bc8f7e687cb6fc34a7aa67f5a7faf7f.png",
              productId: "647af177b1c30752d5e12d80",
              productName: "Bionik Wallet",
              quantity: 1,
              sku: "menswallet-black",
              slug: "bionik-wallet",
              price: 0,
              prices: [],
            },
          ],
          createdAt: "2023-08-29T06:56:15.658Z",
          coupon: null,
          giftCard: null,
          id: "h4zdttexeghblu0rsxiev0m3",
          items: [
            {
              productId: "647af324b1c30752d5e166e1",
              sku: "baerskin1-grey-l",
              slug: "baerskin-hoodie",
              bundleItems: null,
              variantId: "64e5df83091fdb000166c6f3",
              variantName: "Grey, L",
              productName: "The BÆRSkin Tactical Hoodie 2.0",
              quantity: 2,
              image:
                "https://assets.stg.div.haus/89f700ff9300f528dc14ffc262c836be.png",
              prices: [{ min: 1, max: 2, value: 9995 }],
              price: 9995,
            },
            {
              productId: "647aeb6cb1c30752d5e0cc53",
              sku: "vanguardsweater-black-l",
              slug: "baerskin-vanguard-sweater",
              bundleItems: null,
              variantId: "648886f847bd3d0001ae7ebc",
              variantName: "L, Black",
              productName: "BÆRSkin Vanguard Sweater",
              quantity: 1,
              image:
                "https://assets.stg.div.haus/58e243900fbc4e722a04cb09aacb8c24.png",
              prices: [{ min: 1, max: 2, value: 3498 }],
              price: 3498,
            },
            {
              productId: "647af643b1c30752d5e1b033",
              sku: "baerskinfem-grey-m",
              slug: "the-baerskin-tactical-hoodie-2-0-women",
              bundleItems: null,
              variantId: "64887f76bb5f220001834fba",
              variantName: "Grey, M",
              productName: "The BÆRSkin Tactical Hoodie 2.0 Women",
              quantity: 1,
              image:
                "https://assets.stg.div.haus/1fef0dbdd82b7448a0099ad85a84513e.jpg",
              prices: [{ min: 1, max: 2, value: 9995 }],
              price: 9995,
            },
            {
              productId: "647ae98ee74a03e6b22d4270",
              sku: "lumberjacket-green-l",
              slug: "baerskin-lumberjacket",
              bundleItems: null,
              variantId: "64886c19bb5f22000183277f",
              variantName: "L, Dark Green",
              productName: "BÆRSkin Lumberjacket",
              quantity: 1,
              image:
                "https://assets.stg.div.haus/70ddcdac5bb3ade75a25977cc3f280e2.png",
              prices: [{ min: 1, max: 2, value: 9960 }],
              price: 9960,
            },
          ],
          method: "PAYMENT_CARD",
          totals: {
            grandTotal: 43443,
            shipping: 0,
            subTotal: 43443,
            tax: 0,
            tip: 0,
          },
          uid: "7T48QC4J",
          updatedAt: "2023-08-30T07:49:00.416Z",
        },
      ],
      uid: "P64YNHVA",
      updatedAt: "2023-08-30T07:49:00.416Z",
      totals: {
        grandTotal: 43443,
        shipping: 0,
        subTotal: 43443,
        tax: 0,
        tip: 0,
      },
      bonusItems: [
        {
          image:
            "https://assets.div.haus/SWAT Thinsulate Beanie-web-image â 5.png",
          productId: "647f1cc8ccf118e34879594f",
          productName: "BÆRSkin Thinsulate Beanie",
          quantity: 1,
          sku: "swatbeanie-black",
          slug: "baerskin-thinsulate-beanie",
          price: 0,
          prices: [],
        },
        {
          image: "https://assets.div.haus/1bc8f7e687cb6fc34a7aa67f5a7faf7f.png",
          productId: "647af177b1c30752d5e12d80",
          productName: "Bionik Wallet",
          quantity: 1,
          sku: "menswallet-black",
          slug: "bionik-wallet",
          price: 0,
          prices: [],
        },
      ],
      bonusLevel: 3,
      coupon: null,
      giftCard: null,
    },
    version: 1,
  }

  const setOrder = () => {
    setValue(fullOrder)
    push("/order/confirm/")
  }

  return (
    <div className="flex flex-row gap-2">
      <Link
        href={`${process.env.NEXT_PUBLIC_APP_URL}/order/7RN1FMGL/?customer=3b2a30a662c14c03a2936492839309c1cc92bdfafb9f8649bbdb3032739f4989/`}
        className="inline-block p-2 text-white bg-red-600"
      >
        Goto Order
      </Link>
      <button className="p-2 text-white bg-red-600" onClick={setOrder}>
        Build and Go
      </button>
    </div>
  )
}

export const DivDevTools = () => {
  const [isOpen, setIsOpen] = useState(false)
  const localCart = useCart()
  const multiCart = useMultiCartStore()
  const giftCart = useGiftCart()
  const { items: GiftWrapItems } = useGiftWrap()

  const open = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button
        className="fixed bottom-0 left-2 hover:opacity-90 z-[9999999999] -mt-1/2"
        onClick={open}
      >
        <Image alt="risitas" src={Icon} width={62} height={35} />
      </button>
      {isOpen && (
        <div
          className="fixed bottom-0 left-0 z-[99999999] w-full text-white h-60"
          style={{ fontFamily: "Comic Sans MS", backgroundColor: "#6c6c6c" }}
        >
          <header
            className="flex items-center justify-between px-3 py-1 mb-2 text-lg border border-b-black border-l-white border-r-black border-t-white"
            style={{ backgroundColor: "#394dcd" }}
          >
            <h4>
              <span className="line-through">Dev</span> div tools
            </h4>
            <button
              className="px-2 py-1 border border-b-black border-l-white border-r-black border-t-white"
              style={{ backgroundColor: "#6c6c6c" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              X
            </button>
          </header>
          <div className="grid grid-cols-1 p-3 space-y-2 sm:grid-cols-2">
            <div>
              <p>
                <strong>Cart ID:</strong> `
                <span className="font-mono">{localCart.id}</span>`
              </p>
              <p>
                <strong>Cart UID:</strong> `
                <span className="font-mono">{localCart.uid}</span>`
              </p>
              <p>
                <strong>Cart channel:</strong> `
                <span className="font-mono">{localCart.channel}</span>`
              </p>
              <p>
                <strong>Cart Coupon:</strong> `
                <span className="font-mono">
                  {localCart.coupon?.code || "none"}
                </span>
                `
              </p>
              <p className="flex flex-row gap-4">
                <button
                  className="underline"
                  onClick={() => console.log(localCart)}
                >
                  Console cart
                </button>
                <button
                  className="underline"
                  onClick={() => console.log(multiCart)}
                >
                  Console multicart
                </button>
                <button
                  className="underline"
                  onClick={() => console.log(giftCart)}
                >
                  Console Gift Cart
                </button>
                <button
                  className="underline"
                  onClick={() => console.log(GiftWrapItems)}
                >
                  Console Gift Wrap Cart
                </button>
                <button
                  className="underline"
                  onClick={() => {
                    Cookies.remove("div-cart")
                    Cookies.remove("div-cart-short")
                  }}
                >
                  Test cart cookies removal{" "}
                  <span className="text-xxs">(breaks stuff)</span>
                </button>
              </p>
            </div>
            <CartFaker />
            <CartNuker />
            <LocaleNuker />
            <SkipToOrder />
          </div>
        </div>
      )}
    </>
  )
}

export default DivDevTools
