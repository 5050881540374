import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"../../packages/anchor/src/utils/fonts.ts\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"]}],\"variableName\":\"barlow\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/DoctorQuoteBlock/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/opt/build/repo/packages/anchor/src/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/Faq/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/anchor/src/components/Highlights/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/RemoteVideoBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewsBlock"] */ "/opt/build/repo/packages/anchor/src/components/Reviews/Block.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/Section/styles.module.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/Section/withVideo.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/SenjaReviews.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/UserContent/styles.module.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/YoutubeVideoBlock/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/checkout/src/checkout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/checkout/src/paypalWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/Price.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/RegionSelector.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/bunnyLoader.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/cleanStaleCartId.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/clearRandomIdsFromBundles.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDetectDevice.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentReady.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentVisible.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useLocalStorage.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useOutsideClickCapture.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useScroll.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useWindowWidth.tsx");
