import { create, Mutate, StoreApi } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"
import Cookies from "js-cookie"
import { fetchApi } from "@local/utils/src/fetchApi"
import { sleep } from "@local/utils/src/sleep"
import type { CartEventInput } from "./api/event"
import type { CouponHandlerResponse } from "./api/coupon"
import {
  trackAction,
  trackFbq,
  // trackLiveIntent,
  trackNbpix,
  trackPinterest,
  trackSnapchat,
  trackTtq,
  trackTwitter,
} from "@local/tracking/src/trackActions"
import { Currency } from "@local/i10n"
import { getShippingCost } from "./shipping"
import type { CartItem, CartState, DiscountMethod, Total } from "./types"
import type { OrderItem } from "@local/order/src/useOrder/types"
import { sendBacon } from "@local/utils/src/sendBeacon"
import { objectToShiftedBase64 } from "@local/utils/src/safeBase64"
import { setId } from "./id"
import { clearDecimals } from "@local/utils/src/clearDecimals"
import { captureException, setContext } from "@sentry/nextjs"
import { createId } from "./createId"

export const unfoldCartItems = (foldedCart: CartItem[]) => {
  const newCart: CartItem[] = []
  foldedCart.forEach((item) => {
    if (item.quantity > 1) {
      for (let i = 0; i < item.quantity; i++) {
        newCart.push({
          ...item,
          quantity: 1,
        })
      }
    } else {
      newCart.push(item)
    }
  })
  return newCart
}

const foldCartItems = (unfoldedCart: CartItem[]) => {
  const newCart: Map<string, CartItem> = new Map()
  unfoldedCart.forEach((item) => {
    const existingItem = newCart.get(`${item.productId}_${item.variantId}`)
    if (existingItem) {
      newCart.set(`${item.productId}_${item.variantId}`, {
        ...item,
        quantity: existingItem.quantity + 1,
      })
    } else {
      newCart.set(`${item.productId}_${item.variantId}`, item)
    }
  })

  return Array.from(newCart.values())
}

interface CalculationInput {
  cart: Array<CartItem>
  currency: Currency
  isAnchor?: boolean
  isUpsell?: boolean
}

const calculateCartTotals = ({
  cart,
  currency,
  isAnchor,
  isUpsell,
}: CalculationInput) => {
  let subTotal = 0

  // Calculate prices on the first item if it's an anchor page
  // if (isAnchor && cart.length > 0) {
  //   const quantity = unfoldCartItems(cart).length
  //   const price = cart[0].prices
  //     .sort((a, b) => a.value - b.value)
  //     .find((p) => p.min <= quantity)?.value as number

  //   cart.forEach((_, index) => {
  //     cart[index].price = price
  //   })
  //   subTotal = price * quantity
  // } else {
  // Unfold cart so we can measure the tiers for proper pricing
  const unfoldedCart = unfoldCartItems(cart)
  const uniqueProducts = new Map(
    unfoldedCart
      .filter((item) => item.type !== "giftcard") // same id, different prices, special case
      .map((item) => [item.productId, 0])
  )

  Array.from(uniqueProducts.keys()).forEach((productId) => {
    // Get all items for this product
    const item = unfoldedCart.find(
      (item) => item.productId === productId
    ) as CartItem
    const items = unfoldedCart.filter((item) => item.productId === productId)

    // Get the quantity of items for this product
    const quantity = items.reduce((acc, item) => acc + item.quantity, 0)

    // Get the price for this product
    const price = item?.prices
      ?.sort((a, b) => a.value - b.value)
      .find((p) => p.min <= quantity)?.value as number

    uniqueProducts.set(productId, price)
  })

  cart.forEach((item, index) => {
    if (item.type === "giftcard") {
      // Handle special case
      subTotal += item.price! * item.quantity
      cart[index].price = item.price
    } else {
      // Need to take tiers into account
      const price = uniqueProducts.get(item.productId) as number

      if (price) {
        cart[index].price = price
        subTotal += price * item.quantity
      }
    }
  })
  // }

  // This is required in cases the cart gets to zero
  if (cart.length === 0) {
    console.log("cart length is zero")
    subTotal = 0
  }

  // If the cart only contains giftcarts, it means it is all digital and should not have shipping.
  const onlyGiftcards = cart.every((item) => item.type === "giftcard")

  // Get Shipping Cost
  const shipping =
    isAnchor || isUpsell || onlyGiftcards
      ? 0
      : getShippingCost(subTotal, currency)

  const grandTotal = subTotal + shipping
  const totals = {
    grandTotal,
    shipping,
    subTotal,
    tax: 0,
    tip: 0,
  } as Total

  return {
    cart,
    totals,
  }
}

interface DiscountInput {
  amount: number
  method: DiscountMethod
  min?: number | null
}

interface DiscountCalculationInput {
  discount?: DiscountInput | null
  giftCard?: number | null
  totals: Total
}

const calculateDiscount = ({
  discount,
  giftCard,
  totals,
}: DiscountCalculationInput) => {
  const subTotal = clearDecimals(totals.subTotal, 0)
  let grandTotal = subTotal
  let discountTotal = null

  if (discount) {
    discountTotal =
      discount.method === "amount"
        ? clearDecimals(discount.amount * 100)
        : // Remove decimals, we were having cases where the discount was 1799.5 and there was a mismatch
          // between the amount charged and the amount saved in our system
          clearDecimals((subTotal * discount.amount) / 100, 0)

    // Avoid discount from applying if there is a minimum amount on the card
    if (discount.min && totals.subTotal < discount.min) {
      discountTotal = 0
    }
  } else {
    discountTotal = 0
  }

  grandTotal = subTotal - discountTotal + totals.shipping

  if (giftCard) {
    grandTotal = grandTotal - giftCard
  }

  if (grandTotal < 0) {
    grandTotal = 0
  }

  const newTotals = {
    discount: clearDecimals(discountTotal, 0),
    giftCard,
    grandTotal: clearDecimals(grandTotal, 0),
    shipping: clearDecimals(totals.shipping, 0),
    subTotal,
    tax: 0,
    tip: 0,
  } as Total

  return {
    totals: newTotals,
  }
}

type StoreWithPersist = Mutate<
  StoreApi<CartState>,
  [["zustand/persist", unknown]]
>

/**
 * This storage below allows the user cart to persist between tabs
 * This "rehydrates" the front-end whenever a storage event happens
 * Not my code, stole from here: https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#how-can-i-rehydrate-on-storage-event
 * @param store This is the cart storage
 * @returns void
 */
export const withStorageDOMEvents = (store: StoreWithPersist) => {
  // Without this, on server side things will breaking bad
  if (typeof window === "undefined") return

  const storageEventCallback = (e: StorageEvent) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      store.persist.rehydrate()
    }
  }

  window.addEventListener("storage", storageEventCallback)

  return () => {
    window.removeEventListener("storage", storageEventCallback)
  }
}

export const useCart = create<CartState>()(
  persist(
    (setState, getState) => ({
      channel: null,
      createdAt: new Date(),
      currency: null,
      error: null,
      id: null,
      items: [] as CartItem[],
      uid: null,
      updatedAt: new Date(),
      totals: {
        subTotal: 0,
        tax: 0,
        tip: 0,
        shipping: 0,
        grandTotal: 0,
      },
      addCoupon: async ({ code, currency }) => {
        const currencyToUse = getState().currency ?? (currency as Currency)

        runCoupon: {
          // Skip the code if it's blank, might be a bot
          if (!code) {
            break runCoupon
          }
          const clearCode = code.trim().toUpperCase()
          const basket = getState().totals.grandTotal
          getState().beamCartEvent({
            action: "addCoupon",
            contents: { code },
            operation: "update",
          })
          const req = await fetchApi<
            Pick<CouponHandlerResponse, "amount" | "error" | "method" | "min">
          >({
            endpoint: "/api/cart/coupon/",
            options: {
              skipError: true,
            },
            method: "POST",
            data: {
              code: clearCode,
              currency: currencyToUse,
              basket,
            },
          })

          if (req && !req.error) {
            const method = req.method
            const { totals } = calculateDiscount({
              discount: {
                amount: Number(req.amount),
                min: req.min,
                method,
              },
              giftCard: getState().totals.giftCard || null,
              totals: getState().totals,
            })
            setState({
              coupon: {
                amount: Number(req.amount),
                code: clearCode,
                method,
                min: req.min,
              },
              error: null,
              totals,
              updatedAt: new Date(),
              currency: currencyToUse,
            })
            trackAction("add_coupon", {
              cart_id: getState().uid,
              coupon_id: clearCode,
            })
            getState().beamCartEvent({
              action: "addedCoupon",
              contents: { code },
              operation: "update",
            })
          } else {
            if (req?.error === "Minimum spend not reached") {
              setState({
                error: "COUPON_MINIMUM",
              })
            } else {
              setState({
                error: "COUPON_INVALID",
              })
            }
            getState().beamCartEvent({
              action: "addCouponFail",
              contents: { code },
              operation: "update",
            })
          }

          // Easter Egg
          if (code === "please") {
            setState({
              error: "COUPON_POLITE",
            })
          }
        }

        return {
          error: getState().error,
          id: getState().id as string,
          items: getState().items,
          currency: getState().currency,
          totals: getState().totals,
        }
      },
      addGiftCard: async (code) => {
        getState().beamCartEvent({
          action: "addGiftCard",
          contents: { code },
          operation: "update",
        })
        const req = await fetchApi<{ balance?: number }>({
          endpoint: "/api/cart/giftcard/",
          method: "POST",
          options: { skipError: true },
          data: { code },
        })
        if (req?.balance) {
          const { totals } = calculateDiscount({
            discount: getState().coupon
              ? {
                  amount: getState().coupon?.amount || 0,
                  min: getState().coupon?.min,
                  method: getState().coupon?.method || "amount",
                }
              : null,
            giftCard: parseInt(req.balance.toString()),
            totals: getState().totals,
          })
          setState({
            giftCard: {
              code,
              amount: Number(req.balance),
            },
            error: null,
            totals,
            updatedAt: new Date(),
          })
          trackAction("add_giftcard", {
            amount: parseInt(req.balance.toString()),
            cart_id: getState().uid,
          })
          getState().beamCartEvent({
            action: "addedGiftCard",
            contents: { code },
            operation: "update",
          })
        } else {
          setState({
            error: "GIFTCARD_INVALID",
          })
          getState().beamCartEvent({
            action: "addGiftCardFail",
            contents: { code },
            operation: "update",
          })
        }
        return {
          id: getState().id,
          error: getState().error,
          items: getState().items,
          currency: getState().currency,
          totals: getState().totals,
        }
      },
      addItems: ({
        channel,
        currency,
        isAnchor,
        items,
        location,
        noTrack,
        trackActionBuffered = trackAction,
      }) => {
        // avoid STORE direct cart editing in case of upsell
        /*
        const valid = getState().validateChannel(channel)
        const isUpsell = getState().isUpsellCart
        if (!valid && isUpsell && channel === "baerskintactical") {
          return getState()
        }
        */

        // Fetch old cart to iterate on top of it
        const oldCart = [...getState().items]

        const currencyToUse = getState().currency ?? (currency as Currency)

        // Check if there is a cart ID just in case
        if (!getState().id || !getState().uid) {
          const { id, uid } = setId()
          setState({ id, uid })
        }

        // Let's get this into a cart
        for (const item of items) {
          const existingItemIndex = oldCart.findIndex(
            (i) =>
              i.productId === item.productId && i.variantId === item.variantId
          )

          // Logic to never add products without sku
          // Special case for bundle items
          if (item.bundleItems && item.bundleItems.length > 0) {
            // Special case for Bundle Items, treat them as separate to avoid a long-haul of array comparison
            // All items from within a bundle must have sku
            if (item.bundleItems.every((i) => i.sku)) {
              oldCart.push(item)
            }
          } else if (item.sku && item.productId) {
            if (existingItemIndex > -1) {
              oldCart[existingItemIndex].quantity += item.quantity
            } else {
              oldCart.push(item)
            }
          } else {
            setContext("cart", { items })
            captureException("ItemAdd: Null SKU item in cart")
          }
        }

        // Calculate totals with tiers
        let { cart, totals } = calculateCartTotals({
          cart: oldCart,
          currency: currencyToUse as Currency,
          isAnchor,
        })

        for (const item of cart) {
          if (item.bundleItems && item.bundleItems.length > 0) {
            for (const bundleItem of item.bundleItems) {
              if (!bundleItem.sku) {
                setContext("cart", { cart })
                captureException(
                  "ItemAdd after calculate totals: Null SKU item in cart"
                )
              }
            }
          } else if (!item.sku) {
            setContext("cart", { cart })
            captureException(
              "ItemAdd after calculate totals: Null SKU item in cart"
            )
          }
        }

        if (getState().coupon?.code) {
          const coupon = calculateDiscount({
            discount: getState().coupon
              ? {
                  amount: getState().coupon?.amount as number,
                  method: getState().coupon?.method || "amount",
                  min: getState().coupon?.min,
                }
              : null,
            giftCard: getState().giftCard?.amount || null,
            totals,
          })
          totals = coupon.totals
        }

        if (!noTrack) {
          trackActionBuffered("add_to_cart", {
            currency: currencyToUse || "USD",
            value: totals.grandTotal / 100,
            items: items.map((item, i) => ({
              item_id: item?.variantId ?? (item?.productId as string),
              item_name: item.productName,
              item_variant: item.variantName,
              location_id: location,
              position: i + 1,
              price:
                item.price && item.price > 999 ? item.price / 100 : item.price,
              quantity: item.quantity,
              item_variant_id: item?.variantId,
              image_url: item?.image,
              // TODO: item_category_id: ,
            })),
          })
          trackFbq("AddToCart", {
            currencyToUse,
            content_ids: items.map((item) => item.variantId ?? item.productId),
            content_type: "product",
          })
          trackTwitter("tw-ojbee-ojbeg", {})
          trackTtq("AddToCart", {
            contents: items.map((item) => ({
              content_id: item.variantId ?? item.productId,
              content_type: "product",
              content_name: item.productName,
            })),
          })
          trackPinterest("addtocart", {
            event_id: "eventId0001",
            currency: currencyToUse,
            line_items: items.map((item) => ({
              product_name: item.productName,
              product_id: item.productId,
            })),
          })
          trackNbpix()
          // trackLiveIntent("addToCart", {
          //   name: "product_purchase",
          //   items: items.map((item) => ({
          //     id: item.variantId ?? item.productId,
          //     quantity: item.quantity,
          //     price: ((item.price ?? 0) / 100).toFixed(2),
          //     currency: currencyToUse,
          //   })),
          // })
          trackSnapchat("ADD_CART", {
            item_ids: items.map((item) => item.variantId ?? item.productId),
            number_items: items.length,
          })
        }

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { items })
          captureException("Null SKU item in add cart")
        }

        // Set to state
        setState({
          channel,
          currency: currencyToUse,
          error: null,
          items: [...cart],
          totals,
          updatedAt: new Date(),
        } as CartState)

        getState().beamCartEvent({
          action: "addItems",
          contents: { items },
          operation: "update",
        })

        // Return the cart for other needs
        return {
          id: getState().id,
          error: getState().error,
          items: [...cart],
          totals,
        }
      },
      beamCartEvent: async ({ action, contents, operation }) => {
        const cart = getState()
        const deploymentUrl = process.env.NEXT_PUBLIC_APP_URL || ""
        const endpoint = `${deploymentUrl}/api/cart/event/`
        const recart =
          window && window._recart
            ? (window._recart.getSessionId() as string)
            : Cookies.get("ghostmonitor_session_id")
        const currencyToUse = getState().currency ?? Cookies.get("div-currency")

        if (cart.id && cart.uid) {
          const data = {
            action,
            channel:
              window && window.channel
                ? window.channel
                : process.env.NEXT_PUBLIC_CHANNEL_ID,
            cookies: document.cookie,
            contents,
            currency: currencyToUse,
            id: cart.id,
            operation,
            recart,
            snapshot: cart,
            uid: cart.uid,
          } as CartEventInput

          const blob = new Blob([objectToShiftedBase64(data, 24)], {
            type: "text/plain",
          })
          sendBacon(endpoint, blob)
        }
      },
      changeQuantity: async ({
        amount,
        currency,
        index,
        isAnchor,
        trackActionBuffered = trackAction,
      }) => {
        const items = getState().items
        const itemToChange = items[index]
        const diff = itemToChange.quantity - amount

        const currencyToUse = getState().currency ?? (currency as Currency)

        if (amount <= 0) {
          return getState().removeItem(index, currencyToUse, isAnchor)
        }

        // change the item
        itemToChange.quantity = amount
        items[index] = itemToChange

        // Calculate totals with tiers
        let { cart, totals } = calculateCartTotals({
          cart: items,
          currency: currencyToUse,
          isAnchor,
        })

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { items })
          captureException("Null SKU item change quantity")
        }

        // In case a coupon exists
        if (getState().coupon?.code) {
          const coupon = calculateDiscount({
            discount: getState().coupon
              ? {
                  amount: getState().coupon?.amount as number,
                  method: getState().coupon?.method || "amount",
                  min: getState().coupon?.min,
                }
              : null,
            giftCard: getState().giftCard?.amount || null,
            totals,
          })
          totals = coupon.totals
        }

        if (diff < 0) {
          trackActionBuffered("add_to_cart", {
            currency: currencyToUse || "USD",
            value: totals.grandTotal / 100,
            items: [
              {
                item_id: itemToChange.variantId ?? itemToChange.productId,
                item_name: itemToChange.productName,
                item_variant: itemToChange.variantName,
                location_id: "cart",
                position: index + 1,
                price:
                  itemToChange.price && itemToChange.price > 999
                    ? itemToChange.price / 100
                    : itemToChange.price,
                quantity: Math.abs(diff),
                item_variant_id: itemToChange?.variantId,
                image_url: itemToChange?.image,
                // TODO: item_category_id: ,
              },
            ],
          })
          trackFbq("AddToCart", {
            currency: currencyToUse,
            content_ids: [itemToChange.variantId ?? itemToChange.productId],
            content_type: "product",
          })
          trackTwitter("tw-ojbee-ojbeg", {})
          trackTtq("AddToCart", {
            contents: [
              {
                content_id: itemToChange.variantId ?? itemToChange.productId,
                content_type: "product",
                content_name: itemToChange.productName,
              },
            ],
          })
          trackPinterest("addtocart", {
            event_id: "eventId0001",
            currency: currencyToUse,
            line_items: items.map((item) => ({
              product_name: item.productName,
              product_id: item.productId,
            })),
          })
          trackNbpix()

          trackSnapchat("ADD_CART", {
            item_ids: [itemToChange.variantId ?? itemToChange.productId],
            number_items: index + 1,
          })
        } else {
          trackAction("remove_from_cart", {
            currency: currencyToUse,
            value:
              itemToChange.price && itemToChange.price > 999
                ? itemToChange.price / 100
                : itemToChange.price,
            items: [
              {
                item_id: itemToChange.variantId
                  ? `${itemToChange.productId}_${itemToChange.variantId}`
                  : itemToChange.productId,
                item_name: itemToChange.productName,
                quantity: itemToChange.quantity,
                variant: itemToChange.variantName,
              },
            ],
          })
        }

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { items })
          captureException("Null SKU item in quantity of cart")
        }

        // Set to state
        setState({
          items: [...cart],
          error: null,
          totals,
          updatedAt: new Date(),
          currency: currencyToUse,
        } as CartState)

        // getState().beamCartEvent({
        //   action: "changeQuantity",
        //   contents: { itemToChange, amount },
        //   operation: "update",
        // })

        return getState()
      },
      convertToOrder: async ({
        bonusItems,
        cb,
        customer,
        currency,
        id,
        method,
        uid,
      }) => {
        if (!method) {
          throw new Error("convertToOrder: Invalid payment method")
        }

        const cart = getState()
        const currencyToUse = getState().currency ?? (currency as Currency)

        const isGrandTotalValid =
          cart.totals.grandTotal > 0
            ? true
            : cart.totals.grandTotal <= 0 &&
                cart.giftCard &&
                cart.giftCard.amount >= cart.totals.subTotal
              ? true
              : false

        if (cart.items.length > 0 && isGrandTotalValid) {
          const items = cart.items.map((item) => {
            let bundleVariant
            if (item.bundleItems && item.bundleItems?.length > 0) {
              bundleVariant = item.bundleItems
                .map((item) => `${item.productName} (${item.variantName})`)
                .join(", ")
            }

            const variantId =
              item.bundleItems && item.bundleItems.length > 0
                ? null
                : item.variantId
            const variantName =
              item.bundleItems && item.bundleItems.length > 0
                ? null
                : item.variantName

            return {
              //if type is undefined OR type is bundle, then send bundleItems
              //this ensures no unpaid items are later unfolded on the backend order
              bundleItems:
                !item.type || item.type === "bundle" ? item.bundleItems : [],
              image: item.image,
              isAddon: item.isAddon,
              isGift: false,
              preOrder: item.preOrder,
              price: item.price,
              productId: item.productId,
              productName: item.productName,
              quantity: item.quantity,
              sku: item.sku,
              slug: item.slug,
              type: item.type,
              variantId,
              variantName: bundleVariant ?? variantName,
            }
          }) as OrderItem[]

          //gifts
          if (bonusItems) {
            const gifts = bonusItems.map((item) => {
              if (item.cartItem) {
                return {
                  image: item.image,
                  slug: "none",
                  isGift: true,
                  price: 0,
                  productId: item.cartItem.productId,
                  productName: item.cartItem.productName,
                  quantity: 1,
                  sku: item.cartItem.sku,
                  variantId: item.cartItem.variantId,
                  variantName: item.cartItem.variantName,
                }
              }
            }) as OrderItem[]
            items.push(...gifts)
          }

          const order = {
            coupon: cart.coupon,
            currency: currencyToUse,
            customer,
            createdAt: new Date(),
            giftCard: cart.giftCard,
            method,
            id: id ?? cart.id,
            items,
            totals: cart.totals,
            uid: uid ?? cart.uid,
          }

          if (order) {
            // This is an interesting fallback test
            if (window) {
              window.$lo = order.uid
            }
            cb(order)

            trackAction("convert_cart", {
              cart_id: getState().uid,
            })

            await getState().beamCartEvent({
              action: "convertToOrder",
              contents: { totals: cart.totals, method },
              operation: "delete",
            })

            //aparently this remotion here does nothing on staging!
            Cookies.remove("div-cart")
            Cookies.remove("div-cart-short")

            const { id, uid } = setId()

            setState({
              coupon: null,
              error: null,
              giftCard: null,
              id,
              items: [],
              uid,
              totals: {
                subTotal: 0,
                tax: 0,
                tip: 0,
                shipping: 0,
                grandTotal: 0,
              },
              createdAt: new Date(),
              updatedAt: new Date(),
            })
          }
          await sleep(600)
        }
      },
      getCount: () => {
        const items = getState().items
        const count = items.reduce((acc, cur) => acc + cur.quantity, 0)
        return count
      },
      getUnfoldedItems: () => {
        return unfoldCartItems(getState().items)
      },
      removeCoupon: (noTrack) => {
        const { totals } = calculateDiscount({
          discount: null,
          giftCard: getState().totals.giftCard,
          totals: getState().totals,
        })

        if (!noTrack) {
          getState().beamCartEvent({
            action: "removeCoupon",
            contents: { coupon: getState().coupon },
            operation: "update",
          })

          trackAction("remove_coupon", {
            cart_id: getState().uid,
            code: getState().coupon,
          })
        }

        setState({
          coupon: null,
          error: null,
          totals,
          updatedAt: new Date(),
        })

        return {
          id: getState().id,
          error: getState().error,
          items: getState().items,
          currency: getState().currency,
          totals: getState().totals,
        }
      },
      removeGiftCard: () => {
        const { totals } = calculateDiscount({
          discount: getState().coupon
            ? {
                amount: getState().coupon?.amount as number,
                method: getState().coupon?.method || "amount",
                min: getState().coupon?.min,
              }
            : null,
          giftCard: null,
          totals: getState().totals,
        })
        getState().beamCartEvent({
          action: "removeGiftCard",
          contents: { giftCard: getState().giftCard },
          operation: "update",
        })
        setState({
          giftCard: null,
          error: null,
          totals,
          updatedAt: new Date(),
        })

        trackAction("remove_giftcard", {
          cart_id: getState().uid,
        })

        return {
          id: getState().id,
          uid: getState().id,
          error: null,
          items: getState().items,
          currency: getState().currency,
          totals: getState().totals,
        }
      },
      removeAnchorItem: (item, currency) => {
        const oldCart = getState().items
        const unfoldedCart = unfoldCartItems(oldCart)
        const filteredCart = unfoldedCart.filter((_, index) => index !== item)
        const removedItem = unfoldedCart.find(
          (_, index) => index === item
        ) as CartItem

        const currencyToUse = getState().currency ?? (currency as Currency)

        if (removedItem) {
          const newCart = foldCartItems(filteredCart)
          const { cart, totals } = calculateCartTotals({
            cart: newCart,
            currency: currencyToUse as Currency,
            isAnchor: true,
          })

          // Set to state
          setState({
            items: [...cart],
            error: null,
            totals,
            updatedAt: new Date(),
          })

          getState().beamCartEvent({
            action: "removeItem",
            contents: { item },
            operation: "update",
          })

          trackAction("remove_from_cart", {
            currency: currencyToUse,
            value: removedItem.price,
            items: [
              {
                item_id: removedItem.sku,
                item_name: removedItem.productName,
                quantity: removedItem.quantity,
                variant: removedItem.variantName,
              },
            ],
          })

          return {
            id: getState().id,
            uid: getState().uid,
            error: null,
            items: [...cart],
            totals,
          }
        }

        // Return the cart for other needs
        return {
          id: getState().id,
          uid: getState().uid,
          error: null,
          items: [],
          totals: getState().totals,
        }
      },
      removeItem: (item, currency, isAnchor) => {
        const oldCart = getState().items
        const newCart = oldCart.filter((_, index) => index !== item)
        const removedItem = oldCart.find(
          (_, index) => index === item
        ) as CartItem

        const currencyToUse = getState().currency ?? (currency as Currency)

        const { cart, totals } = calculateCartTotals({
          cart: newCart,
          currency: currencyToUse as Currency,
          isAnchor,
        })

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { cart })
          captureException("Null SKU item in remove item from cart")
        }

        // Set to state
        setState({
          items: [...cart],
          error: null,
          totals,
          updatedAt: new Date(),
        })

        getState().beamCartEvent({
          action: "removeItem",
          contents: { item },
          operation: "update",
        })

        trackAction("remove_from_cart", {
          currency: currencyToUse,
          value: removedItem.price,
          items: [
            {
              item_id: removedItem.sku,
              item_name: removedItem.productName,
              quantity: removedItem.quantity,
              variant: removedItem.variantName,
            },
          ],
        })

        // Return the cart for other needs
        return {
          id: getState().id,
          uid: getState().uid,
          error: getState().error,
          items: [...cart],
          totals,
        }
      },
      reset: async (convert, channel) => {
        if (!convert) {
          getState().beamCartEvent({
            action: "resetCart",
            contents: {},
            operation: "delete",
          })
        }
        Cookies.remove("div-cart")
        Cookies.remove("div-cart-short")

        setState({
          id: null,
          uid: null,
        })

        const { id, uid } = setId()
        setState({
          coupon: null,
          error: null,
          giftCard: null,
          id,
          items: [],
          uid,
          totals: {
            subTotal: 0,
            tax: 0,
            tip: 0,
            shipping: 0,
            grandTotal: 0,
          },
          createdAt: new Date(),
          updatedAt: new Date(),
          isUpsellCart: false,
          currency: null,
          channel,
        })

        return getState()
      },
      restore: async (input, currency) => {
        if (
          input.items.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { item: input.items })
          captureException("Null SKU item in restore cart")
        }

        const cartId = input.id ?? createId().id
        const cartUid = input.uid ?? createId().uid

        // Calculate totals with tiers
        const { cart, totals } = calculateCartTotals({
          cart: input.items,
          currency: currency ?? input.currency ?? Currency.USD,
        })

        setState({
          createdAt: input.createdAt,
          currency: currency ?? input.currency,
          error: input.error,
          id: cartId,
          isUpsellCart: input.isUpsellCart ?? false,
          items: cart,
          totals: totals,
          uid: cartUid,
          updatedAt: new Date(),
        })

        getState().beamCartEvent({
          action: "restoreCart",
          contents: {},
          operation: "update",
        })

        return getState()
      },
      replaceItems: ({
        channel,
        currency,
        isAnchor,
        isUpsell,
        items,
        location,
        noTrack,
        trackActionBuffered = trackAction,
      }) => {
        // avoid STORE direct cart editing in case of upsell
        // const valid = getState().validateChannel(channel)
        // if (!valid && isUpsell && channel === "baerskintactical") {
        //   return getState()
        // }

        const newCart: CartItem[] = []

        const currencyToUse = getState().currency ?? (currency as Currency)

        // Check if there is a cart ID just in case
        if (!getState().id || !getState().uid) {
          const { id, uid } = setId()
          setState({ id, uid })
        }

        // Let's get this into a cart
        for (const item of items) {
          // Special case for Bundle Items, treat them as separate to avoid a long-haul of array comparison
          if (item.bundleItems && item.bundleItems.length > 0) {
            newCart.push(item)
          } else {
            // Need to treat variants as same for product but different for cart
            newCart.push(item)
          }

          if (
            item.sku == null &&
            (!item.bundleItems || item.bundleItems.length <= 0)
          ) {
            setContext("cart", { items })
            captureException("Null SKU item in replace cart")
          }
        }

        // Calculate totals with tiers
        let { cart, totals } = calculateCartTotals({
          cart: foldCartItems(newCart),
          currency: currencyToUse as Currency,
          isAnchor,
          isUpsell,
        })

        if (getState().coupon?.code) {
          const coupon = calculateDiscount({
            discount: getState().coupon
              ? {
                  amount: getState().coupon?.amount as number,
                  method: getState().coupon?.method || "amount",
                  min: getState().coupon?.min,
                }
              : null,
            giftCard: getState().giftCard?.amount || null,
            totals,
          })
          totals = coupon.totals
        }

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { items })
          captureException("Null SKU item in replace cart")
        }

        if (!noTrack) {
          trackActionBuffered("add_to_cart", {
            currency: currencyToUse || "USD",
            value: totals.grandTotal / 100,
            items: items.map((item, i) => ({
              location_id: location,
              item_id: item?.variantId ?? (item?.productId as string),
              item_name: item.productName,
              item_variant: item.variantName,
              position: i + 1,
              price:
                item.price && item.price > 999 ? item.price / 100 : item.price,
              quantity: item.quantity,
              item_variant_id: item?.variantId,
              image_url: item?.image,
              // TODO: item_category_id: ,
            })),
          })
          trackFbq("AddToCart", {
            currency: currencyToUse,
            content_ids: items.map((item) => item.variantId ?? item.productId),
            content_type: "product",
          })
          trackTwitter("tw-ojbee-ojbeg", {})
          trackTtq("AddToCart", {
            contents: items.map((item) => ({
              content_id: item.variantId ?? item.productId,
              content_type: "product",
              content_name: item.productName,
            })),
          })
          trackPinterest("addtocart", {
            event_id: "eventId0001",
            currency: currencyToUse,
            line_items: items.map((item) => ({
              product_name: item.productName,
              product_id: item.productId,
            })),
          })
          trackNbpix()
          trackSnapchat("ADD_CART", {
            item_ids: items.map((item) => item.variantId ?? item.productId),
            number_items: items.length,
          })
        }

        if (
          cart.some(
            (item) =>
              !item.sku && (!item.bundleItems || item.bundleItems.length <= 0)
          )
        ) {
          setContext("cart", { items })
          captureException("Null SKU item in replace cart")
        }

        // Set to state
        setState({
          channel,
          items: [...cart],
          error: null,
          totals,
          updatedAt: new Date(),
          isUpsellCart: isUpsell,
          currency: getState().currency ?? currencyToUse,
        } as CartState)

        getState().beamCartEvent({
          action: "addItems",
          contents: { items },
          operation: "update",
        })

        // Return the cart for other needs
        return {
          id: getState().id,
          error: getState().error,
          items: [...cart],
          totals,
          isUpsellCart: isUpsell,
        }
      },
      setError: (input) => {
        const cart = getState()

        setState({
          error: input,
        })

        return {
          ...cart,
          error: input,
        }
      },
      setTip: (amount) => {
        const newAmount = clearDecimals(amount, 0)

        if (newAmount < 0) {
          return {
            id: getState().id,
            error: getState().error,
            items: getState().items,
            currency: getState().currency,
            totals: getState().totals,
          }
        }

        // Amount cannot have decimals, it breaks the checkout
        // Fetch old cart to iterate on top of it
        const totals = getState().totals
        const currentTip = totals.tip ?? 0

        const diff = newAmount - currentTip
        const newGrandTotal = diff + totals.grandTotal

        const newTotals = {
          ...totals,
          tip: newAmount,
          grandTotal: newGrandTotal,
        } as Total

        // Set to state
        setState({
          totals: newTotals,
          updatedAt: new Date(),
        } as CartState)

        getState().beamCartEvent({
          action: "addTip",
          contents: { amount: newAmount },
          operation: "update",
        })

        // Return the cart for other needs
        return {
          id: getState().id,
          error: getState().error,
          items: getState().items,
          currency: getState().currency,
          totals: newTotals,
        }
      },
      validateChannel: (channel) => {
        const parentChannelId = process.env.NEXT_PUBLIC_CHANNEL_ID as string
        const channelToCheck = channel ?? parentChannelId
        const currentChannel = getState().channel

        /**
         * This is a logic to check if:
         * 1. The channel is different from what existed in the cart
         * 2. The channel is the parent channel, in this case, baerskintactical
         * There's "not much problem" LP-to-LP scenario, as the LP will always
         * replace the cart on any change, visit or refresh.
         * The devil lies in LP-to-store navigation.
         */

        // console.log("channelToCheck: ", channelToCheck)
        // console.log("currentChannel: ", currentChannel)
        // console.log("parentChannelId: ", parentChannelId)
        // console.log(
        //   "currentChannel !== channelToCheck: ",
        //   currentChannel !== channelToCheck
        // )
        // console.log(
        //   "currentChannel !== parentChannelId: ",
        //   currentChannel !== parentChannelId
        // )
        // console.log("\n")

        if (
          channelToCheck &&
          currentChannel &&
          currentChannel !== channelToCheck &&
          (currentChannel === parentChannelId ||
            channelToCheck === parentChannelId)
        ) {
          // console.log(
          //   "Cross-contamination of carts",
          //   "background: #222; color: #bada55"
          // )
          return false
        }
        return true
      },
    }),
    {
      name: "cart",
      storage: createJSONStorage(() => localStorage),
      version: 49,
    }
  )
)

// This glues the things together
withStorageDOMEvents(useCart)
