"use client"

import Script from "next/script"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Klarna as TKlarna } from "./types"
import { useI10n } from "@local/i10n"
import { useCart } from "@local/cart/src/useCart"
import { useGiftCart } from "@local/cart/src/useGiftCart"
import { trackPurchase } from "@local/tracking/src/purchase"
import { getKlarnaSessionOrderPayload } from "./helpers/getKlarnaSessionOrderPayload"
import { clearDecimals, fetchApi } from "@local/utils"
import * as Sentry from "@sentry/nextjs"
import { useRouter } from "next/navigation"
import { LoadingOverlay } from "@local/ui/src/LoadingOverlay"
import { trackAction } from "@local/tracking/src/trackActions"
import ErrorBoundary from "./ErrorBoundary"

const KLARNA_WEBHOOK_URL =
  process.env.NEXT_PUBLIC_STAGE === "stg"
    ? "https://hooks.div.haus/blfsjxvou8q83v" //channels-coin-klarna-stg
    : "https://hooks.div.haus/dctuqbdfs2doa2" //channels-coin-klarna-prd

const DEFAULT_CURRENCY_CODE = "USD"
const DEFAULT_LOCALE = "en-US"

type Props = TKlarna.KlarnaCheckoutSetupProps & { slug?: string | null }

export function KlarnaCheckoutSetup({
  idSufix,
  upsell,
  onLoaded,
  onOrderLoading,
  onSuccess,
  slug,
}: Props) {
  const {
    locale,
    country: i10nCountry,
    getCountryFromCookie,
    currencyCode,
  } = useI10n()
  const cart = useCart()
  const { bonusItems } = useGiftCart()
  const { push } = useRouter()
  const countryFromCookie = getCountryFromCookie()
  const scriptLoaded = useRef(!!upsell ? true : false)

  const country = useMemo(() => {
    return !countryFromCookie || countryFromCookie === "eu"
      ? i10nCountry.toUpperCase()
      : countryFromCookie.toUpperCase()
  }, [countryFromCookie, i10nCountry])

  const [session, setSession] =
    useState<TKlarna.CreatePaymentsSessionResponse | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const body = useMemo(() => {
    return getKlarnaSessionOrderPayload({
      cart,
      bonusItems,
      currencyCode: currencyCode || DEFAULT_CURRENCY_CODE,
      locale,
      country,
      upsell,
      slug,
    })
  }, [cart, currencyCode, locale, bonusItems, country, upsell, slug])

  const handleCreateWebHookOrder = useCallback(
    async (klarnaData: {
      order: TKlarna.CreatePaymentsOrderResponse
      body: TKlarna.CreatePaymentsSessionRequest
    }) => {
      try {
        await fetch(KLARNA_WEBHOOK_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(klarnaData),
        })

        trackPurchase({
          cart,
          currencyCode,
        })

        if (!onSuccess) {
          setIsSuccess(true)
          push("/order/redirect/klarna/")
          return
        }
        onSuccess("klarna")
      } catch (error) {
        console.error(error)
        Sentry.captureException(
          `Checkout Error: ${error} at onCheckoutComplete`
        )
      }
    },
    [cart, currencyCode, onSuccess, push]
  )

  const createKlarnaOrder = useCallback(
    async (
      authorizationToken: string,
      collectedShippingAddress: TKlarna.CollectedShippingAddress
    ) => {
      onOrderLoading(true)

      try {
        const currentBody = {
          ...body,
          shipping_address: collectedShippingAddress,
        }

        const order = await fetchApi<TKlarna.CreatePaymentsOrderResponse>({
          endpoint: "/api/klarna/payments/order",
          method: "POST",
          data: {
            body: currentBody,
            authorizationToken: authorizationToken,
            idempotencyKey: crypto.randomUUID(),
          },
        })

        // @ts-ignore
        if (!order || order.error_code) {
          console.error(order)
          throw new Error("Couldn't create Klarna order")
        }

        if (order.order_id && order.fraud_status === "ACCEPTED") {
          await handleCreateWebHookOrder({ order, body: currentBody })
        }
      } catch (e) {
        console.error(e)
        return
      } finally {
        onOrderLoading(false)
        setSession(null)
      }
    },
    [body, handleCreateWebHookOrder, onOrderLoading]
  )

  const createSession = useCallback(async () => {
    try {
      // Stop here if it's a very old browser
      if (typeof crypto === "undefined") return
      // Stop here if no item in the cart yet
      if (!body.order_lines.length) return

      const session = await fetchApi<TKlarna.CreatePaymentsSessionResponse>({
        endpoint: "/api/klarna/payments/session",
        method: "POST",
        data: {
          body,
          idempotencyKey: crypto.randomUUID(),
        },
      })

      if (!session) {
        throw new Error("Couldn't create Klarna session")
      }

      setSession(session)
    } catch (e) {
      console.error(e)
      onLoaded()
      return
    }
  }, [body, onLoaded])

  const init = useCallback(() => {
    if (!session) return

    /**
     * This is needed as an error code will trigger an entire
     * meltdown of the component, so make sure to keep this check
     * in this place
     */
    if (
      session.client_token &&
      window.hasOwnProperty("Klarna") &&
      window.Klarna.hasOwnProperty("Payments")
    ) {
      const containerId = !!idSufix
        ? `klarna-express-checkout-container-${idSufix}`
        : "klarna-express-checkout-container"
      const wrapperId = !!idSufix
        ? `klarna-express-checkout-wrapper-${idSufix}`
        : "klarna-express-checkout-wrapper"

      document.querySelector(`#${containerId}`)?.remove()
      const buttonContainerElement = document.createElement("div")
      buttonContainerElement.id = containerId
      buttonContainerElement.className = "w-full"
      const buttonWrapper = document.querySelector(`#${wrapperId}`)
      buttonWrapper?.append(buttonContainerElement)

      window.Klarna.Payments.Buttons.init({
        client_token: session.client_token,
      })
      window.Klarna.Payments.Buttons.load(
        {
          container: `#${containerId}`,
          theme: "default",
          shape: "default",
          locale: DEFAULT_LOCALE,
          on_click: (authorize: any) => {
            trackAction("begin_checkout", {
              value: clearDecimals(cart.totals.grandTotal / 100, 2),
              currency: currencyCode,
              items: cart.items.map((item, i) => ({
                item_id: item?.variantId ?? (item?.productId as string),
                item_name: item.productName,
                item_variant: item.variantName,
                list: "Checkout",
                position: i + 1,
                price: clearDecimals((item.price ?? 0) / 100, 2),
                quantity: item.quantity,
                image_url: item.image ?? null,
                item_variant_id: item?.variantId ?? null,
              })),
              discount: clearDecimals((cart.totals.discount ?? 0) / 100, 2),
              gateway: "klarna",
            })

            authorize(
              { auto_finalize: true, collect_shipping_address: true },
              body,
              (result: any) => {
                if (result.approved) {
                  createKlarnaOrder(
                    result.authorization_token,
                    result.collected_shipping_address
                  )
                }
              }
            )
          },
        },
        () => {
          onLoaded()
        }
      )
    }
  }, [body, cart, currencyCode, createKlarnaOrder, onLoaded, session, idSufix])

  useEffect(() => {
    if (!session || !session.client_token) {
      createSession()
    } else {
      if (scriptLoaded.current) {
        init()
      }
    }
  }, [createSession, init, session, cart])

  return (
    <ErrorBoundary>
      <Script
        defer
        src="https://x.klarnacdn.net/kp/lib/v1/api.js"
        onLoad={() => {
          scriptLoaded.current = true
        }}
      />
      {isSuccess && !onSuccess && (
        <LoadingOverlay goto="/order/redirect/klarna/" />
      )}
    </ErrorBoundary>
  )
}
