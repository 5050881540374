"use client"
import type { Payload } from "@local/payload-client/src/types"
import { useMemo, useRef } from "react"
import useElementObserver from "../../hooks/useElementObserver"
import YoutubeEmbed from "../YoutubeEmbed"
// import { isBrowser } from "@local/utils"
// import { TrustpilotMicroCombo } from "../TruspilotWidgets"
// import HydrationContainer from "../HydrationContainer"

interface Props {
  block: Payload.YtVideoBlock
  trustpilotBuid?: string | null
}

export const YouTubeVideoBlockServer = ({ block, trustpilotBuid }: Props) => {
  const videoRef = useRef<HTMLDivElement | null>(null)
  const title = useMemo(
    () =>
      typeof block.headline === "object"
        ? block.headline["en"]
          ? block.headline["en"]
          : block.headline
        : "",
    [block]
  )

  useElementObserver({
    elementRef: videoRef,
    observeIntersection: true,
  })

  return (
    <section
      id="lp-youtube-video"
      className="px-4 "
      style={{
        backgroundColor: block.backgroundColor ?? undefined,
        color: block.textColor ?? undefined,
      }}
    >
      <div className="flex flex-col items-center px-4 py-12 mx-auto sm:py-12 sm:px-6 lg:px-8 ">
        <h2
          className="max-w-5xl mb-6 text-4xl font-normal text-center uppercase sm:text-5xl"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="relative w-full max-w-6xl mb-4 aspect-video"
          ref={videoRef}
        >
          <YoutubeEmbed
            embedId={block.youtubeVideoId}
            allowControls={false}
            autoplay
            mute
            loop
            elementId="lp-youtube-video-embed"
          />
        </div>
        <div className="w-full -mb-6 text-center">
          <p className="-mb-4 text-xl font-semibold sm:text-2xl">
            Over 9000 5-Star Reviews
          </p>
          {/* <HydrationContainer>
            <TrustpilotMicroCombo buid={trustpilotBuid} theme="dark" />
          </HydrationContainer> */}
        </div>
      </div>
    </section>
  )
}

export default YouTubeVideoBlockServer
