"use client"
import Image from "next/image"
import Link from "next/link"
import { RegionSelector } from "@local/i10n/src/RegionSelector"
import { ErrorBoundary } from "./ErrorBoundary"
import type { Payload } from "@local/payload-client/src/types"
import { Fragment, Suspense } from "react"
import { usePageLayout } from "../usePageLayout"
import { CreditCards } from "./CreditCards"
import { BunnyLoader } from "@local/utils/src/bunnyLoader"
import { GET_ANY_CHANNEL_ID, ITEM_BRAND } from "../const"
import { OrganizationSchemaJsonLd } from "@local/utils/src/jsonLdSchemas/Organization"
import { useI10n } from "@local/i10n/src/useI10n"
// import { TrustpilotMicroCombo } from "./TruspilotWidgets"
// import { isBrowser } from "@local/utils"
import SubPages from "./SubPages"
import HydrationContainer from "./HydrationContainer"

interface Props {
  minimal?: boolean
  noExternal?: boolean
  page: Payload.Anchor
  showSelector?: boolean
  storeId?: Payload.AnchorChannelId
  trustpilotBuid?: string | null
  slug?: string
}

export const Footer = ({
  minimal,
  noExternal,
  page,
  showSelector,
  storeId,
  trustpilotBuid,
  slug,
}: Props) => {
  const { togglePage } = usePageLayout()
  const { currencyCode } = useI10n()

  let isPartOf = null
  switch (storeId) {
    case "cozislides":
      isPartOf = {
        name: "Cozi Slides Store",
        url: "https://store.cozislides.com/",
      }
      break
    case "hamslides":
    case "ham":
      isPartOf = {
        name: "Hyper Arch Motion Store",
        url: "https://store.hyperarchmotion.com/",
      }
      break
    case "baerskinhoodie":
    case "freedomslides":
    case "nobstees":
    case "rainjacket":
    case "softshell":
    case "vest":
    default:
      isPartOf = {
        name: "Baerskin Tactical",
        url: "https://baerskintactical.com/product/baerskin-tactical-hoodie-4-0/",
      }
      break
  }

  const price = ((currencyCode &&
    currencyCode !== "USD" &&
    page.product?.[0]?.prices?.[0]?.[currencyCode]) ??
    page.product?.[0]?.prices[0].price) as number

  return (
    <footer
      id="footer"
      style={{
        background: page.footer?.backgroundColor,
        color: page.footer?.textColor,
      }}
      className="px-4 pt-4 pb-8 font-display md:pb-4"
      role="contentinfo"
    >
      <div className="container mx-auto">
        {page.logo && (
          <div className="mx-auto block py-4 text-center max-w-[200px]">
            <Image
              src={page.logo.url}
              alt={page.name}
              width={page.logo.width}
              height={page.logo.height}
              loader={
                process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined
              }
            />
          </div>
        )}

        {/* {(GET_ANY_CHANNEL_ID(storeId).IS_BAERSKIN ||
          GET_ANY_CHANNEL_ID(storeId).IS_HAM) &&
          trustpilotBuid && (
            <TrustpilotMicroCombo buid={trustpilotBuid} tall price={price} />
          )} */}
        {!minimal && (
          <div className="grid grid-cols-1 gap-8 py-4 sm:grid-cols-2">
            <div>
              <h5 className="mb-2 text-xs font-bold tracking-wider uppercase font-display">
                Support
              </h5>
              <nav>
                <ul className="space-y-2 text-xs">
                  <li id="footer-nav-about">
                    <button
                      className="hover:underline"
                      onClick={() => {
                        togglePage("about")
                      }}
                    >
                      About Us
                    </button>
                  </li>
                  <li id="footer-nav-privacy">
                    <button
                      className="hover:underline"
                      onClick={() => {
                        togglePage("privacy")
                      }}
                    >
                      Privacy Policy
                    </button>
                  </li>
                  <li id="footer-nav-payments">
                    <button
                      className="hover:underline"
                      onClick={() => {
                        togglePage("payments")
                      }}
                    >
                      Secure Payments
                    </button>
                  </li>
                  <li id="footer-nav-terms">
                    <button
                      className="hover:underline"
                      onClick={() => {
                        togglePage("terms")
                      }}
                    >
                      Terms & Conditions
                    </button>
                  </li>
                  <li id="footer-nav-shipping">
                    <button
                      className="hover:underline"
                      onClick={() => {
                        togglePage("shippingReturns")
                      }}
                    >
                      Shipping & Returns
                    </button>
                  </li>
                  <li id="footer-nav-contact">
                    <Link
                      href={`${slug ? `/lp/${slug}` : ""}/contact-us`}
                      prefetch={false}
                    >
                      Contact Us
                    </Link>
                  </li>
                  {!noExternal && (
                    <li id="footer-nav-order">
                      <Link href="/order/" prefetch={false}>
                        Find my order
                      </Link>
                    </li>
                  )}
                  {GET_ANY_CHANNEL_ID(storeId).IS_BAERSKIN && !noExternal && (
                    <li id="footer-nav-business">
                      <Link href="/business/" prefetch={false}>
                        Business orders
                      </Link>
                    </li>
                  )}
                  {/*(GET_ANY_CHANNEL_ID(storeId).IS_HAM ||
                    GET_ANY_CHANNEL_ID(storeId).IS_BAERSKIN) &&
                    !noExternal && (
                      <>
                        <li id="footer-nav-ambassador">
                          <a
                            href="https://baerskinambassadors.trysaral.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Redirect to become a brand ambassador in a new tab"
                          >
                            Become a brand ambassador
                          </a>
                        </li>
                      </>
                    )*/}
                </ul>
              </nav>
              <section className="mt-8 ">
                {page.footer?.fields?.map((item) => (
                  <Fragment key={item.id}>
                    <h5 className="mb-2 text-xs font-bold tracking-wider uppercase font-display">
                      {item.title}
                    </h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content as string,
                      }}
                      className="mb-3"
                    />
                  </Fragment>
                ))}
              </section>
            </div>
            <div>&nbsp;</div>
          </div>
        )}
        <div className="block mx-auto mt-8 text-xs text-center">
          <div className="my-4">
            <ErrorBoundary>
              <Suspense>
                <RegionSelector hidden={showSelector ? false : true} />
              </Suspense>
            </ErrorBoundary>
          </div>

          <div className="max-w-xl mx-auto mb-3 ">
            <CreditCards />
          </div>
          <p className="mb-3">
            © 2015 - {new Date().getFullYear()}
            <br />
            {page.name}
            {isPartOf && (
              <>
                <br />
                is part of{" "}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href={isPartOf.url}
                  target="_blank"
                  className="text-gray-500"
                  aria-label={`Redirect to ${isPartOf.name} in a new tab`}
                >
                  {isPartOf.name}
                </a>
              </>
            )}
          </p>
          <p>All rights reserved</p>
        </div>
      </div>
      <HydrationContainer>
        <SubPages about={page.about} storeId={storeId} />
      </HydrationContainer>
      <OrganizationSchemaJsonLd
        brand={ITEM_BRAND ?? "Baerskin Tactical"}
        item={page}
        slug={slug || page.slug || undefined}
      />
    </footer>
  )
}

export default Footer
